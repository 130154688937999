
	import { defineComponent, ref, onMounted } from "vue";
	import { ErrorMessage, Field, Form } from "vee-validate";
	import { Actions } from "@/store/enums/StoreEnums";
	import { useStore } from "vuex";
	import { useI18n } from "vue-i18n";
	import { useRouter, useRoute } from "vue-router";
	import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
	import Swal from "sweetalert2/dist/sweetalert2.min.js";
	import * as Yup from "yup";
	import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
	import Information1 from "@/components/dropdown/Information1.vue";
	import ElCurrencyInput from "@/components/CurrencyInput.vue";
	import { ElLoading } from "element-plus";
	import moment from "moment";
	import { Action } from "vuex-module-decorators";
	import { object } from "yup/lib/locale";
	import ElInfo from "@/components/ElInfo.vue";
	import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
	export default defineComponent({
		name: "balanceSheet2",
		components: {
			ElCurrencyInputNoSymbol,
		},
		async mounted() {
			this.init();
			//this.initDays();
		},

		data() {
			const statements = {
				nonCurrentAssets: {
					label: "Non Current Assets",
					child: {
						PropertyPlantEquipment: {
							label: "  Property,plant & equipment",
							value: [],
						},
						Other: {
							label: "Other (please specify)",
							value: [],
						},
					},
				},
				totalNonCurrentAssets: {
					label: "Total Non Current Assests",
					value: [],
				},

				nonCurrentLiabilities: {
					label: "Non Current Liabilities",
					child: {
						LongTermLoans: {
							label: " Long-term loans",
							value: [],
						},
						Other: {
							label: "Other (please specify)",
							value: [],
						},
					},
				},
				totalNonCurrentLiabilities: {
					label: "Total Non Current Liabilities",
					value: [],
				},

				currentAssests: {
					label: "Current Assets",
					child: {
						CashInHand: {
							label: "Cash-in-hand",
							value: [],
						},
						BankDeposit: {
							label: "Bank Deposits",
							value: [],
						},
						Inventories: {
							label: "Inventories",
							value: [],
						},
						Debtors: {
							label: "Debtors",
							value: [],
						},
						PrepaidPayments: {
							label: "Prepaid payments",
							value: [],
						},
						Other: {
							label: "Other (please specify)",
							value: [],
						},
					},
				},
				totalCurrentAssests: {
					label: "Total Current Assests",
					value: [],
				},

				currentLiabilities: {
					label: "Current Liabilities",
					child: {
						Creditors: {
							label: " Creditors",
							value: [],
						},
						ShortTermloans: {
							label: "Short-Terms loans",
							value: [],
						},
						CustomersPrepayments: {
							label: "Customer prepayments/ Advanced from owner",
							value: [],
						},
						Other: {
							label: "Other (please specify)",
							value: [],
						},
					},
				},
				totalCurrentLiabilities: {
					label: "Total Current Liabilities",
					value: [],
				},

				Shareholders: {
					label: "Shareholders equity",
					child: {
						Shareholders: {
							label: " Shareholders' equity/ Owner's capital",
							value: [],
						},
						CapitalWithdrawal: {
							label: "Capital Withdrawal",
							value: [],
						},
						RetainedEarnings: {
							label: "Retained Earnings",
							value: [],
						},
						AccumulatedLoss: {
							label: "Accumulated loss",
							value: [],
						},
					},
				},
				totalShareholders: {
					label: "Total Shareholder's Equity",
					value: [],
				},
			};
			return {
				statements,
			};
		},
		setup() {
			return {};
		},

		methods: {
			init() {
				setCurrentPageBreadcrumbs("Balance Sheet", []);
			},
			onSubmit(values) {
				console.log(values);
			},
			//    initDays() {

			//       for (let key of Object.keys(this.statements.nonCurrentAssets.child)) {
			//         let nonCurrentAssetsChild = this.statements.nonCurrentAssets.child;
			//          for (let index =0; index < 3 ; index++) {
			//           nonCurrentAssetsChild[key].value.push(0);
			//         }
			//       }

			//  },
		},
	});
