
import { defineComponent, onMounted } from "vue";
import KTTableWidgetBalanceSheet from "@/components/widgets/tables/WidgetBalanceSheet.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetBalanceSheet,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Balance Sheet", ["Financial Statements","Balance Sheet"]);
    });
  },
});
